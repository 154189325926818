<template lang="pug">
  // Root element of PhotoSwipe. Must have class pswp.
  .pswp(tabindex="-1" role="dialog" aria-hidden="true")
    .pswp__bg
    .pswp__scroll-wrap
      .pswp__container
        .pswp__item
        .pswp__item
        .pswp__item

      .pswp__ui.pswp__ui--hidden
        .pswp__top-bar
          .pswp__counter

          button.pswp__button.pswp__button--close(title="Close (Esc)")
          button.pswp__button.pswp__button--share(title="Share")
          button.pswp__button.pswp__button--fs(title="Toggle fullscreen")
          button.pswp__button.pswp__button--zoom(title="Zoom in/out")

          .pswp__preloader
            .pswp__preloader__icn
              .pswp__preloader__cut
                .pswp__preloader__donut

        .pswp__share-modal.pswp__share-modal--hidden.pswp__single-tap
          .pswp__share-tooltip

        button.pswp__button.pswp__button--arrow--left(title="Previous (arrow left)")
        button.pswp__button.pswp__button--arrow--right(title="Next (arrow right)")

        .pswp__caption
          .pswp__caption__center
</template>
<script>
  import PhotoSwipe from 'photoswipe/dist/photoswipe'
  import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'

  export default {
    data() {
      return {
        /**
         * @see https://github.com/dimsemenov/PhotoSwipe/blob/master/website/documentation/getting-started.md#-creating-an-array-of-slide-objects
         */
        items: [
          {
            src: '/assets/images/service-jet/hero.jpg',
            w: 800,
            h: 600,
            msrc: '/assets/images/service-jet/hero.jpg',
            title: 'Image Caption 1'
          }, {
            src: '/assets/images/service-jet/hero.jpg',
            w: 800,
            h: 600,
            msrc: '/assets/images/service-jet/hero.jpg',
            title: 'Image Caption 2'
          }
        ],
        options: {
          index: 0
        }
      }
    },
    mounted() {
      console.log('--->', this.$refs.pswp)
      const gallery = new PhotoSwipe(
        // this.$refs.pswp,
        document.querySelector('.pswp'),
        PhotoSwipeUI_Default,
        this.items,
        this.options
      )

      gallery.init();

      console.log('gallery>>>', gallery)
    }
  }
</script>
