import Vue from 'vue'
import store from '@/store'
import FeedbackForm from '@/components/site/feedback/FeedbackForm';

function start() {
  /**
   * поиск всех элементов на странице
   * и создание виджетов
   */
  document
    .querySelectorAll('.widget-contacts-form')
    .forEach((el) => {
      new Vue({
        el: el,
        store,
        render: h => h(FeedbackForm)
      })
  })
}

export default {
  start
}
