import Swiper, { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper'

Swiper.use([Navigation, Pagination, Scrollbar, Autoplay])

//require('swiper/swiper.scss')
require('./style.scss')

function start() {
  /**
   * @see https://swiperjs.com/api/
   */
  return new Swiper('.swiper-index-services', {
    spaceBetween: 16,
    slidesPerView: 3,
    watchSlidesProgress: true,
    autoplay: {
      delay: 3000
    },
    pagination: {
      el: '.swiper-pagination.swiper-pagination-index-services',
      clickable: true,
      dynamicBullets: true
    },
    breakpoints: {
      320: {
        slidesPerView: 1
      },
      768: {
        slidesPerView: 2
      },
      1024: {
        slidesPerView: 3
      }
    }
  })
}

export default {
  start
}
