import Vue from 'vue'
import Photoswipe from './Photoswipe.vue'
import './styles.scss'

function start(selector) {
  /**
   * можно использовать один и тот же узел DOM
   * или создавать его динамически каждый раз при
   * вызове галереи
   */
  selector = String(selector) || '.pswp-gallery'
  console.log('pswp selector', selector)

  new Vue({
    render: (h) => h(Photoswipe)
  }).$mount(selector)
}


export default {
  start,
  Photoswipe
}
