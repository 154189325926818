import Vue from 'vue'
import store from '@/store'
import OrderTrigger from '@/components/site/order/OrderTrigger';


function start() {
  /**
   * поиск всех элементов на странице
   * и создание виджетов
   */
  document
    .querySelectorAll('.widget-order-trigger')
    .forEach((el) => {
      new Vue({
        el: el,
        store,
        render: h => h(OrderTrigger)
      })
  })
}


export default {
  start
}
