<template lang="pug">
  .order-trigger.button.is-danger(@click.prevent="showForm") Заказать услугу
</template>
<script>
  import OrderForm from '@/components/site/order/OrderForm'
  import store from '@/store'

  /**
   * кнопка триггер для
   * отображения формы записи на ремонт
   *
   * <div class="widget-order-to-service-trigger"></div>
   */
  export default {
    store,
    methods: {
      showForm() {
        this.$buefy.modal.open({
          parent: this,
          component: OrderForm
        })
      }
    }
  }
</script>
