<template lang="pug">
.platform-side-panel.side-panel-default(:class="{ 'is-active': isActive }")
  .platform-side-panel-item
    a.button.button-side-panel.open-side-panel(@click.stop.capture="toggle")
      span.icon: i.fas.fa-arrow-circle-left
        // img.image.is-24x24(src="/local/components/platform/side-panel/templates/.default/images/arrow.svg" alt="open")
      span.open-side-panel__text Скрыть панель
  .platform-side-panel-item
    a.button.button-side-panel.callback-trigger(@click.stop.capture="callback")
      span.icon.is-32x32
        i.is-32x32.fas.fa-mobile-alt
        //img.image.is-24x24(src="/local/components/platform/side-panel/templates/.default/images/phone.svg"
          alt="Модальное окно")
      span.open-side-panel__text Звонок
  .platform-side-panel-item
    a.button.button-side-panel(href="/services/")
      span.icon: i.fas.fa-bookmark
        // img.image.is-24x24(src="/local/components/platform/side-panel/templates/.default/images/ruler.svg"  alt="Услуги")
      span.open-side-panel__text Каталог
</template>
<script>
import { CallbackForm } from '@/components'
import store from '@/store'
export default {
  store,
  components: {
    CallbackForm
  },
  data () {
    return {
      active: false
    }
  },
  created() {
    /**
     * когда происходит событие,
     * наиболее вложенный элемент будет целевым элементом event.target
     *
     * - сначала событие "движется" сверху от корня документа
     * к целевому элементу, и вызывает обработчики, которые зарегистрированы с
     * опцией `capture: true`
     * - вызывается обработчик зарегистрированный на целевом элементе
     * - затем событие всплывает (bubbles) от целевого элемента к корню документа, при этом
     * вызываются обработчики зарегистрированные без опции {capture: true}.
     *
     * addEventListener('EVENT_NAME', handler, true) - capture: true
     * addEventListener('EVENT_NAME', handler, { capture: true }) - capture: true
     * addEventListener('EVENT_NAME', handler) - capture: false, by default
     *
     * чтобы панель закрывалась при клике на body надо "поймать событие" при всплытии,
     * при этом события в панели должны прерывать всплытие, перехватывать события.
     *
     * для перехвата событий используются модификаторы
     * .stop - остановить всплытие
     * .capture - только целевое событие на элементе
     *
     * @click.stop.capture="clickHandler"
     *
     * При клике на body ожидаем "всплытия" события, при этом если
     * это панель, то событие будет остановлено.
     *
     */
    document.body.addEventListener('click', () => {
      /**
       *
       * в данном случае закрываться будет всегда
       * в том числе при кликах на модальных окнах
       */
      this.active = false
    }, false)
  },
  computed: {
    isActive () {
      return this.active === true
    }
  },
  methods: {
    callback () {
      /**
       * закрыть панель
       */
      this.active = false

      /**
       * модальное окно
       */
      this.$buefy.modal.open({
        parent: this,
        component: CallbackForm,
        canCancel: ['x']
      })
    },
    toggle () {
      this.active = !this.active
    }
  }
}
</script>
<style lang="sass" scoped>
  @import 'bulma-theme'
  .platform-side-panel
    position: fixed
    right: -120px
    top: 50%
    width: 156px
    height: auto
    transition: all .5s ease
    z-index: 1
    background: $primary
    &:hover
      background: darken($primary, 10%)
    .platform-side-panel-item
      border-bottom: 1px solid transparent
    .button-side-panel
      width: 156px
      background: $primary
      border: none
      justify-content: normal
      &:hover
        background: darken($primary, 30%)
        color: #fff
  .open-side-panel
    .icon
      transition: all .24s ease
  .open-side-panel__text
    padding-left: .5rem
    display: inline-block
    color: #fff
    font-weight: 400
    font-size: .875rem
  .platform-side-panel.is-active
    right: 0
    .open-side-panel
      .icon
        transform: rotate(180deg)
</style>
