import $ from 'jquery'
import Vue from 'vue'

/**
 * ключевые системные библиотеки
 * подключаются через "обертки", чтобы
 * иметь возможность предварительной
 * настройки, установки параметров по-умолчанию
 */
import http from './custom/http'
import validation from './custom/validate'
import buefy from './custom/buefy'
import store from './store'

/**
 * если включать роутер по-умолчанию,
 * то ко всем url будет добавляться `#/`,
 * что не всегда является приемлемым
 *
 * Роутер используется для навигации по
 * "экранам", "видам" приложения.
 *
 * Обычно такой подход используется в
 * сложных приложениях. Он позволяет переходить
 * по "видам" приложения используя url.
 */
//import router from './router'

/**
 *
 * main.js - это главный файл проекта, точка
 * подключения всех библиотек, компонентов и стилей
 *
 * здесь происходит глобальная инициализация, если
 * этого нельзя сделать в компонентах.
 *
 * Лучше если в файле будет минимум кода. Всегда
 * анализировать на предмет оптимизации и разделения на отдельные файлы.
 *
 * Стараться хранить инициализацию в конкретных компонентах.
 * Например в индексном файле компонента.
 *
 */
import {StatusBar/*, SidePanel , pswp*/ } from './components'
import navbar from './runtime/navbar/index'
import swiperHero from './runtime/swiper-hero'
import swiperIndexServices from './runtime/swiper-index-services'
import hasBackgroundImage from './runtime/background-images'
import FeedbackForm from '@/components/site/feedback'
import OrderForm from '@/components/site/order'
/**
 * стили проекта (./sass/)
 *
 * при сборке `npm run build` будут извлечены в два
 * одинаковых файла в шаблоне `template_style.css` и
 * `css/app.css` (это одинаковые файлы), битрикс подключит
 * файл `template_style.css` автоматически.
 */
import './sass/main.sass'

/**
 * инициализация библиотек
 *
 * хорошей практикой считается обертывание
 * библиотек в отдельные модули и экспорт
 * функции инициализации.
 *
 * Перед тем, как начать работу с
 * реальными компонентами и приложениями,
 * инициализируем библиотеки.
 *
 */
http.init()
validation.init()
buefy.init()

/**
 * пример создания приложений
 * необходимо проверять существует ли элемент
 * на странице перед монтированием
 *
 * можно ждать загрузки страницы,
 * но можно и не ждать если скрипты
 * подключаются внизу страницы
 *
 * сами компоненты можно воспринимать
 * как виджеты, внедрения на страницу
 *
 * Можно подключаться к готовым элементам
 * страницы, например формам или слайдерам
 *
 * Код инициализации размещен только для примера.
 * Лучше когда инициализация будет в самом компоненте.
 *
 * @todo cleanup
 *
 */
document.addEventListener('DOMContentLoaded', () => {
 // const feedback = document.querySelector('#feedback-form')

 /* if (feedback) {
    new Vue({
      store,
      render: h => h(FeedbackForm)
    }).$mount(feedback)
  }*/

  const statusBar = document.getElementById('platform-status-bar')

  if (statusBar) {
    new Vue({
      store,
      render: h => h(StatusBar)
    }).$mount(statusBar)
  }

  /**
   * Пример создания виджета. Первоначально
   * создается `<div />`, который встраивается
   * в DOM и затем монтируется компонент/приложение.
   *
   * Такой подход делает виджет автономным и
   * независимым от конкретного элемента на странице.
   */
    /*const wrapper = document.createElement('div')
  wrapper.id = 'side-panel'
  document.body.appendChild(wrapper)

  new Vue({
    store,
    render: h => h(SidePanel)
  }).$mount(wrapper)
   */
  /**
   * runtime компоненты и приложения
   *
   * runtime - время исполнения, то есть
   * приложения или компоненты компилируются,
   * и инициализируются в момент загрузки страницы.
   *
   * vue-runtime - это компоненты использующие
   * существующие на странице элементы в качестве шаблонов.
   *
   * к "runtime" мы относим все скрипты, компоненты, приложения,
   * которые привязаны к DOM, к циклу загрузки страницы.
   *
   * Пример runtime приложений, скриптов
   *
   * - любые jquery скрипты
   * - swiper/photoswipe/colorbox и т.п.
   * - javascript утилиты
   *
   *
   * TIP! Vue - приложения в отдельных .vue файлах, отличаются от runtime тем, что
   * компиляция происходит на этапе сборки приложения! Без сборки они работать не будут.
   * Скорость работы этих компонентов выше чем у runtime версий.
   *
   */
     // widgets
 
  FeedbackForm.start()
  OrderForm.start()

  // runtime
  navbar.start()
  swiperHero.start()
  swiperIndexServices.start()
  hasBackgroundImage.start()
  // pswp.start('#gallery')
})

/**
 * выставляем наружу jQuery если это необходимо,
 * также можно выставить любые библиотеки. Обычно
 * это требуется если планируется использовать jQuery
 * в инлайн скриптах и компонентах.
 */
if (typeof window !== 'undefined' && typeof window.$ === 'undefined') {
  window.$ = $
  window.jQuery = $
}
